import request from "@/utils/request";

export function apiListSite(params) {
  return request.get("/site/list", params);
}

export function apiDetailSite(params) {
  return request.get("/site/detail", params);
}

export function apiAddSite(params) {
  return request.post("/site/add", params);
}

export function apiEditSite(params) {
  return request.post("/site/edit", params);
}

export function apiDeleteSite(params) {
  return request.post("/site/delete", params);
}
